<template>
    <div class="main-container">
        <section class="section-contact">
            <h1 class="title-solo">Contact us</h1>
            <p><strong>We Want to Hear From You</strong><br /><br />If you have questions about exhibiting or attending a next generation virtual convention like CryptoVirtua, please feel free to contact us.</p>

            <ContactForm></ContactForm>
        </section>
    </div>
</template>

<script>
import ContactForm from './../components/ContactForm.vue'

export default {
    name : 'Contact',
    metaInfo: {
        title: 'Contact Us',
        meta: [
            { name: 'description', content: 'Send us your message about exhibiting or attending a next generation virtual convention like Ortho Digital Symposium.' },
        ]
    },
    components : {
        ContactForm
    }
}
</script>

<style lang="scss" scoped>
    .main-container {
        background:#f4f4f4;

        background-repeat: no-repeat;
        padding:80px 0;
        margin-top:-80px;

    }

    .section-contact {
        background-image: url('./../assets/bg-contact.jpg');
        background-position: center;
        background-size: cover;
    }

    .title-solo {
        padding-bottom:20px;
    }

    p {
        color: $text-color;
        text-align: center;
        font-size: 18px;
        line-height: 1.5em;
        width: 1000px;
        max-width: 90%;
        margin: -20px auto 40px auto;

    }

    p strong {
        font-size:25px;
    }
</style>